
import { defineComponent, computed } from 'vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import EmailFlow from '@/components/pages/campaigns/emailFlow/EmailFlow.vue'
import EmailFlowBottomBar from '@/components/pages/campaigns/emailFlow/EmailFlowBottomBar.vue'
import useEmailFlow from '@/compositions/emailFlow'

export default defineComponent({
  components: {
    TmAutoSizePanelItemButton,
    PageContent,
    DetailsHero,
    EmailFlow,
    EmailFlowBottomBar,
  },
  setup() {
    const breadcrumbs = computed(() => [
      { label: 'Campaign', url: { name: 'base.campaigns.email' } },
      { label: 'Summer Offer 2024' },
    ])

    const infos = [
      { label: 'Email campaign' },
      { label: 'Draft saved 1 min ago' },
    ]

    const { message } = useEmailFlow()

    return {
      breadcrumbs,
      infos,
      message,
    }
  },
})
