
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepPreview from '@/components/shared/templates/FlowStepPreview.vue'
import SubjectEditor from '@/components/pages/campaigns/emailFlow/SubjectEditor.vue'
import useEmailFlow from '@/compositions/emailFlow'

export default defineComponent({
  components: {
    TmButton,
    FlowStepPreview,
    SubjectEditor,
  },
  setup() {
    const { sender, subject, updateSubject, finishStep } = useEmailFlow()
    const subjectValue = ref({ ...subject.value })
    const onDiscard = () => {
      subjectValue.value = { ...subject.value }
    }
    const stepFunction = () => {
      updateSubject(subjectValue.value)
      finishStep(3)
    }

    return {
      sender,
      subject,
      subjectValue,
      onDiscard,
      stepFunction,
    }
  },
})
