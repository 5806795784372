import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_email_flow = _resolveComponent("email-flow")!
  const _component_email_flow_bottom_bar = _resolveComponent("email-flow-bottom-bar")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    breadcrumbs: _ctx.breadcrumbs,
    "bg-shade": "",
    "overflow-visible": ""
  }, {
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-bottom": _withCtx(() => [
      _createVNode(_component_email_flow_bottom_bar)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        pattern: "blue",
        class: "mb-4",
        icon: "email",
        title: "Summer Offer 2024",
        "title-editable": "",
        infos: _ctx.infos
      }, {
        right: _withCtx(() => [
          _createVNode(_component_tm_auto_size_panel_item_button, {
            icon: "visibility",
            text: "Preview",
            disabled: !_ctx.message
          }, null, 8, ["disabled"]),
          _createVNode(_component_tm_auto_size_panel_item_button, {
            icon: "send",
            text: "Send test email",
            disabled: !_ctx.message
          }, null, 8, ["disabled"])
        ]),
        _: 1
      }, 8, ["infos"]),
      _createVNode(_component_email_flow)
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}