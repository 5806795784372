import { ref } from 'vue'
import type { SenderType, SubjectType } from '@/definitions/campaigns/types'

type StepsType = {
  step: number;
  title: string;
  icon: string;
  editButton: string;
  active?: boolean;
  finished?: boolean;
  withError?: boolean;
}

type UseEmailFlowCompose = {
  recipients: any;
  currentStep: any;
  nextActiveStep: any;
  steps: any;
  sender: any;
  subject: any;
  message: any;
  messageTemplate: any;
  schedule: any;
  updateRecipients: (value: any) => void;
  updateSender: (value: any) => void;
  updateSubject: (value: any) => void;
  updateMessage: (value: any) => void;
  updateMessageTemplate: (value: any) => void;
  updateSchedule: (value: any) => void;
  updateCurrentStep: (value: number) => void;
  closeSteps: () => void;
  updateNextActiveStep: () => void;
  finishStep: (value: number) => void;
  addRecipients: () => void;
  addSender: () => void;
  addSubject: () => void;
  addMessage: () => void;
  addSendTime: () => void;
}

const recipients = ref<any>([])
const currentStep = ref<number>(0)
const nextActiveStep = ref<number>(1)
const steps = ref<StepsType[]>([
  {
    step: 1,
    title: 'To',
    icon: 'tmi-person-group-outline',
    editButton: 'recipients',
  },
  {
    step: 2,
    title: 'From',
    icon: 'tmi-contacts-outline',
    editButton: 'sender',
  },
  {
    step: 3,
    title: 'Subject',
    icon: 'short_text',
    editButton: 'subject',
  },
  {
    step: 4,
    title: 'Content',
    icon: 'mail_outline',
    editButton: 'content',
  },
  {
    step: 5,
    title: 'Send time',
    icon: 'schedule',
    editButton: 'send time',
  },
])
const sender = ref<SenderType>({
  name: '',
  email: 'marketing@company.textmagic.com',
})
const subject = ref<SubjectType>({
  title: '',
  text: '',
})
const message = ref('')
const messageTemplate = ref('')
const schedule = ref('')

const useEmailFlow = (): UseEmailFlowCompose => {
  const updateRecipients = (value: any) => {
    recipients.value = [...value]
  }
  const updateSender = (value: any) => {
    sender.value = { ...value }
  }
  const updateSubject = (value: any) => {
    subject.value = { ...value }
  }
  const updateMessage = (value: any) => {
    message.value = value.slice(0)
  }
  const updateMessageTemplate = (value: any) => {
    messageTemplate.value = value.slice(0)
    steps.value[3].withError = !messageTemplate.value
  }
  const updateSchedule = (value: any) => {
    schedule.value = value.slice(0)
  }
  const updateCurrentStep = (value: number) => {
    currentStep.value = value
  }
  const closeSteps = () => {
    updateCurrentStep(0)
  }
  const updateNextActiveStep = () => {
    if (recipients.value.length === 0) {
      nextActiveStep.value = 1
    } else {
      if (!sender.value.name) {
        nextActiveStep.value = 2
      } else {
        if (!subject.value.title) {
          nextActiveStep.value = 3
        } else {
          if (!message.value) {
            nextActiveStep.value = 4
          } else {
            if (!schedule.value) {
              nextActiveStep.value = 5
            } else {
              nextActiveStep.value = 0
            }
          }
        }
      }
    }
  }
  const finishStep = (value: number) => {
    steps.value[value - 1].finished = true
    closeSteps()
    updateNextActiveStep()
  }
  const addRecipients = () => {
    updateCurrentStep(1)
  }
  const addSender = () => {
    updateCurrentStep(2)
  }
  const addSubject = () => {
    updateCurrentStep(3)
  }
  const addMessage = () => {
    updateCurrentStep(4)
  }
  const addSendTime = () => {
    updateCurrentStep(5)
  }

  return {
    steps,
    recipients,
    currentStep,
    nextActiveStep,
    sender,
    subject,
    message,
    messageTemplate,
    schedule,
    updateRecipients,
    updateSender,
    updateSubject,
    updateMessage,
    updateMessageTemplate,
    updateSchedule,
    updateCurrentStep,
    closeSteps,
    updateNextActiveStep,
    finishStep,
    addRecipients,
    addSender,
    addSubject,
    addMessage,
    addSendTime,
  }
}

export default useEmailFlow
