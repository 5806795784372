
import { defineComponent, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { formatNumber } from '@/services/utils'
import useEmailFlow from '@/compositions/emailFlow'
import EmailSimplePreview from '@/components/shared/templates/emailSimplePreview/EmailSimplePreview.vue'

export default defineComponent({
  components: {
    EmailSimplePreview,
    TmButton,
  },
  setup() {
    const { recipients, subject, message, messageTemplate, addMessage } = useEmailFlow()
    const currentPreview = computed(() => recipients.value.length > 0 ? 1 : 0)
    const currentPreviewLabel = computed(() => recipients.value[0].label)

    return {
      recipients,
      addMessage,
      subject,
      message,
      messageTemplate,
      currentPreview,
      currentPreviewLabel,
      formatNumber,
    }
  },
})
