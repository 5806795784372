
import { defineComponent } from 'vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import EmailFlowStep1 from '@/components/pages/campaigns/emailFlow/EmailFlowStep1.vue'
import EmailFlowStep2 from '@/components/pages/campaigns/emailFlow/EmailFlowStep2.vue'
import EmailFlowStep3 from '@/components/pages/campaigns/emailFlow/EmailFlowStep3.vue'
import EmailFlowStep4 from '@/components/pages/campaigns/emailFlow/EmailFlowStep4.vue'
import EmailFlowStep5 from '@/components/pages/campaigns/emailFlow/EmailFlowStep5.vue'
import EmailFlowPreview from '@/components/pages/campaigns/emailFlow/EmailFlowPreview.vue'
import useEmailFlow from '@/compositions/emailFlow'

export default defineComponent({
  components: {
    FlowStepList,
    EmailFlowStep1,
    EmailFlowStep2,
    EmailFlowStep3,
    EmailFlowStep4,
    EmailFlowStep5,
    EmailFlowPreview,
  },
  setup() {
    const { steps, recipients, currentStep, updateCurrentStep, nextActiveStep } = useEmailFlow()
    const onStepChange = (value: any) => {
      updateCurrentStep(value)
    }

    return {
      steps,
      recipients,
      currentStep,
      nextActiveStep,
      onStepChange,
    }
  },
})
