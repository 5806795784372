
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmSkeleton,
  },
  emits: ['add-content'],
})
