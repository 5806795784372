
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import FlowStepPreview from '@/components/shared/templates/FlowStepPreview.vue'
import InboxPreview from '@/components/pages/tickets/InboxPreview.vue'
import { senderEmails } from '@/definitions/campaigns/data'
import useEmailFlow from '@/compositions/emailFlow'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    FlowStepPreview,
    InboxPreview,
  },
  setup() {
    const { sender, updateSender, finishStep } = useEmailFlow()
    const senderValue = ref({ ...sender.value })
    const onDiscard = () => {
      senderValue.value = { ...sender.value }
    }
    const stepFunction = () => {
      updateSender(senderValue.value)
      finishStep(2)
    }

    return {
      sender,
      senderValue,
      senderEmails,
      onDiscard,
      stepFunction,
    }
  },
})
