
import type { PropType } from 'vue'
import { defineComponent, ref, watch } from 'vue'
import type { SenderType, SubjectType } from '@/definitions/campaigns/types'

export default defineComponent({
  name: 'GmailPreview',
  props: {
    sender: {
      type: Object as PropType<SenderType>,
    },
    subject: {
      type: Object as PropType<SubjectType>,
    },
  },
  setup(props) {
    const lines = ref([
      {
        sender: props.sender,
        subject: props.subject,
        date: 'Jan, 26',
        active: true,
      },
      {
        sender: {
          name: 'Tom Baker',
          email: 'tom@yahoo.com',
        },
        subject: {
          title: 'Call reminder',
          text: 'Hi, just wanted to send a quick reminder about our upcoming events',
        },
        date: 'Feb, 26',
      },
      {
        sender: {
          name: 'Jane Smith',
          email: 'jane@yahoo.com',
        },
        subject: {
          title: 'How are you?',
          text: 'Hi there, just reaching out to check in and see how everything is going. Looking forward to catching up soon',
        },
        date: 'March, 26',
      },
    ])

    watch(() => props.subject, (newValue) => {
      lines.value[0].subject = newValue
    })

    return {
      lines,
    }
  },
})
