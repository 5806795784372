
import type { PropType } from 'vue'
import { defineComponent, ref, watch } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import GmailPreview from '@/components/pages/campaigns/emailFlow/GmailPreview.vue'
import type { SenderType, SubjectType } from '@/definitions/campaigns/types'

export default defineComponent({
  name: 'SubjectEditor',
  components: {
    TmFormLine,
    TmDropdown,
    TmDropdownItem,
    TmTooltip,
    GmailPreview,
  },
  props: {
    sender: {
      type: Object as PropType<SenderType>,
    },
    subject: {
      type: Object as PropType<SubjectType>,
    },
  },
  emits: ['update:subject'],
  setup(props, context) {
    const tags = [
      'First name',
      'Last name',
      'Phone',
      'Email',
      'Company name',
      'Test field',
    ]
    const subjectValue = ref(props.subject)

    watch(() => props.subject, (newValue) => {
      subjectValue.value = newValue
    })

    watch(() => subjectValue.value, (newValue) => {
      context.emit('update:subject', newValue)
    })
    return {
      tags,
      subjectValue,
    }
  },
})
