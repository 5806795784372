
import { defineComponent, ref } from 'vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTemplate from '@/components/shared/TmTemplate.vue'
import FlowStepPreview from '@/components/shared/templates/FlowStepPreview.vue'
import HtmlPreview from '@/components/shared/templates/htmlPreview/HtmlPreview.vue'
import useEmailFlow from '@/compositions/emailFlow'
import { editorBtns } from '@/definitions/shared/editor/data'
import { senderEmailMessageTemplate } from '@/definitions/campaigns/data'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmToggleButtons,
    TmButton,
    TmTemplate,
    FlowStepPreview,
    HtmlPreview,
  },
  setup() {
    const { openModal } = useModals()
    const { message, updateMessage, finishStep, messageTemplate, updateMessageTemplate, addMessage } = useEmailFlow()
    const messageValue = ref(message.value.slice(0))
    const messageTemplateValue = ref(messageTemplate.value.slice(0))
    const onDiscard = () => {
      messageValue.value = message.value.slice(0)
      messageTemplateValue.value = messageTemplate.value.slice(0)
    }
    const stepFunction = () => {
      updateMessage(messageValue.value)
      updateMessageTemplate(messageTemplateValue.value)
      finishStep(4)
    }
    const fixMessageTemplate = () => {
      addMessage()
      messageTemplateValue.value = senderEmailMessageTemplate
    }

    const contentView = ref('visual')

    const btnsList = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.image,
      editorBtns.tags,
      {
        ...editorBtns.templates,
        clickEvent: () => {
          messageTemplateValue.value = senderEmailMessageTemplate
        },
      },
      editorBtns.signature,
      editorBtns.divider,
      editorBtns.expand,
    ]

    const clickTemplates = () => {
      messageTemplateValue.value = senderEmailMessageTemplate
    }

    return {
      openModal,
      message,
      messageValue,
      onDiscard,
      stepFunction,
      fixMessageTemplate,
      messageTemplate,
      messageTemplateValue,
      contentView,
      btnsList,
      clickTemplates,
    }
  },
})
