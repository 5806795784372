
import { defineComponent, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowBottomBar from '@/components/pages/campaigns/FlowBottomBar.vue'
import useEmailFlow from '@/compositions/emailFlow'

export default defineComponent({
  components: {
    TmButton,
    FlowBottomBar,
  },
  setup() {
    const {
      recipients,
      sender,
      subject,
      message,
      schedule,
      addRecipients,
      addSender,
      addSubject,
      addMessage,
      addSendTime,
    } = useEmailFlow()
    const allFilled = computed(() => (recipients.value.length === 0) || !sender.value.name || !subject.value.title || !message.value || !schedule.value)
    const buttonMessage = computed(() => (schedule.value === 'later' ? 'Schedule' : 'Send') + (message.value ? ' 10,234 messages' : ' campaign'))

    const showRecipientComma = computed(() => {
      return (!sender.value.name && !subject.value.title && !message.value && !schedule.value) ||
        (!sender.value.name && !subject.value.title && !message.value) ||
        (!sender.value.name && !subject.value.title && !schedule.value) ||
        (!sender.value.name && !message.value && !schedule.value) ||
        (!subject.value.title && !message.value && !schedule.value) ||
        (!sender.value.name && !subject.value.title) ||
        (!sender.value.name && !message.value) ||
        (!subject.value.title && !message.value) ||
        (!sender.value.name && !schedule.value) ||
        (!subject.value.title && !schedule.value) ||
        (!message.value && !schedule.value)
    })

    const showSenderComma = computed(() => {
      return (!subject.value.title && !message.value && !schedule.value) ||
        (!message.value && !schedule.value) ||
        (!subject.value.title && !message.value) ||
        (!subject.value.title && !schedule.value)
    })

    const showScheduleAnd = computed(() => {
      return (recipients.value.length === 0) || !sender.value.name || !subject.value.title || !message.value
    })

    return {
      recipients,
      sender,
      subject,
      message,
      schedule,
      addRecipients,
      addSender,
      addSubject,
      addMessage,
      addSendTime,
      allFilled,
      buttonMessage,
      showRecipientComma,
      showSenderComma,
      showScheduleAnd,
    }
  },
})
