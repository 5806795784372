
import { defineComponent } from 'vue'
import EmailSimplePreviewPlaceholder from '@/components/shared/templates/emailSimplePreview/EmailSimplePreviewPlaceholder.vue'

export default defineComponent({
  components: {
    EmailSimplePreviewPlaceholder,
  },
  props: {
    message: {
      type: String,
    },
    subject: {
      type: Object,
    },
    messageTemplate: {
      type: String,
    },
    showFooter: {
      type: Boolean,
    },
  },
  emits: ['add-content'],
})
